const keyBy = require('lodash/keyBy')
const get = require('lodash/get')
const snakeCase = require('lodash/snakeCase')
const uuid = require('uuid/v4')
const xlsx = require('@sheet/core')
const { addHours, addDays } = require('date-fns')

const { ProxifiedEntityApi } = require('../common')
const { wrapEntityApi } = require('../utils/wrap-api')
const { decorateRouteWithDetails } = require('./tools/decorate-route-with-details')
const RoutePouchAdapter = require('./data-access/route-pouch-adapter')
const isRouteEditable = require('./tools/is-route-editable')
const RoutePGDataAdapter = require('./data-access/route-pg-data-adapter')
const toRelationalModel = require('./tools/to-relational-model')
const { RouteStatus } = require('./tools')
const { StopMovingHistory } = require('./stop-moving-history')

const api = require('./api')

const ESTIMATED_DELIVERY_DATE_DAYS = 9
const ESTIMATED_PICKUP_DATE_HOURS = 48

const rawMethods = {
  save: api.save,
  get: api.get,
  list: api.list,
  bulkImport: api.bulkImport
}
const entityName = 'route'

const methodsNeedingProxy = [
  'create',
  'update'
]

const isPlannerUser = (user) => {
  if (get(user, 'roles.length', 0) === 0) {
    return false
  }
  return user.roles.includes('feature:settings:routePlanning')
}

class RoutesApi extends ProxifiedEntityApi {
  constructor (state, agaveAdapter, logger, pgConnection, mainApi) {
    const { user, routesDB } = state
    const adapter = new RoutePouchAdapter(user, routesDB)
    super(entityName, methodsNeedingProxy, !pgConnection, adapter, agaveAdapter)

    this.agaveAdapter = agaveAdapter

    if (pgConnection) {
      this.pgDataAdapter = new RoutePGDataAdapter(
        pgConnection,
        user.name,
        logger
      )
    }

    this.mainApi = mainApi

    this.stopMovingHistoryAdapter = new StopMovingHistory(state, agaveAdapter, logger, pgConnection)

    const apiMethods = wrapEntityApi({
      save: api.save,
      bulkImport: api.bulkImport
    }, state)
    Object.assign(this, apiMethods)

    this.state = state
  }

  async moveStop ({ locationId, routeFromId, routeToId }) {
    if (!locationId || !routeFromId || !routeToId) {
      throw new Error(`Cannot move stop: locationId, routeFromId, routeToId are required`, { cause: 'invalid-parameters' })
    }

    if (routeFromId === routeToId) {
      throw new Error(`Cannot move stop: routeFromId and routeToId are the same`, { cause: 'invalid-parameters' })
    }

    const routeFrom = await this.get(routeFromId, { isOnline: true })

    const affectedShipments = routeFrom.shipments
      .filter(shipment => shipment.destination_id === locationId)
      .map(shipment => shipment.shipment_id)

    // If the route changed after this operation was started and there are no shipments
    // connected to the location, we should not proceed with the operation
    if (affectedShipments.length === 0) {
      throw new Error(`Cannot move stop: no shipments found for locationId: ${locationId} and routeFromId: ${routeFromId}`, { cause: 'no-shipments-found' })
    }

    await this.mainApi.shipment.updateRoute({
      shipmentIds: affectedShipments,
      routeId: routeToId
    })

    const stopMovingHistory = {
      location_id: locationId,
      route_from: routeFromId,
      route_to: routeToId,
      route_from_status: routeFrom.status
    }

    return this.stopMovingHistoryAdapter.create(stopMovingHistory)
  }

  /**
   * Getting the details of the route with all the related entities.
   * @param {Object} routeId - The id of the route.
   * @returns {Object} Route object decorated with shipments, locations and funders.
   */
  async getRouteDetails (routeId) {
    if (!routeId) {
      console.error('Cannot get route details: no routeId provided')
      return
    }
    try {
      const route = await this.get(routeId, { isOnline: true })

      const [routeWithDetails] = await decorateRouteWithDetails(
        { routes: [route] },
        this.mainApi
      )
      return routeWithDetails
    } catch (e) {
      if (e.status === 404) {
        console.error('Cannot get route details: route not found', routeId)
        return
      }
      console.error('Error fetching route', routeId, e)
      throw e
    }
  }

  /**
   *
   * @param {Object} Lists all the routes from the database with the corresponding details.
   * @returns the list of routes with details matching the dates
   */
  async listAll () {
    try {
      const { results: routes } = await this.list({}, {isOnline: true})
      return decorateRouteWithDetails({ routes }, this.mainApi)
    } catch (e) {
      if (e.status === 404) {
        console.error('Cannot list routes: routes not found!')
        return
      }
      console.error('Error fetching routes', e)
      throw e
    }
  }

  async assignCarrier (routeIds, carrierId) {
    if (!carrierId) {
      throw new Error(`Cannot update carrier: carrierId is required parameter`)
    }
    if (!routeIds || !Array.isArray(routeIds) || !routeIds.length) {
      throw new Error(`Cannot update carrier: routeIds is required parameter or it can't be empty`)
    }

    const condition = { couchdb_id: routeIds }
    const data = {
      carrier_id: carrierId
    }

    return this.update({condition, data})
  }

  async updateRouteStatus (routeIds, status) {
    if (!status) {
      throw new Error(`Cannot update route status: status is required parameter`)
    }
    if (!Object.values(RouteStatus).includes(status)) {
      throw new Error(`Cannot update route status: status must be one of ${Object.values(RouteStatus).join(', ')}, got: ${status}`)
    }
    if (!routeIds || !routeIds.length) {
      throw new Error(`Cannot update route status: routeIds is required parameter or it can't be empty`)
    }

    // Only planner can update route status to CANCELLED or IN_REVIEW
    const isPlanner = isPlannerUser(this.state.user)
    const plannerRestrictedStatuses = [RouteStatus.CANCELLED, RouteStatus.IN_REVIEW]
    if (!isPlanner && plannerRestrictedStatuses.includes(status)) {
      throw new Error(`Only planner can update route status to ${status}`)
    }

    // Not every status update allowed
    const possibleStatuses = status === RouteStatus.CANCELLED
      ? [RouteStatus.PROVISIONAL] // we can only allow to cancel provisional routes
      : Object.values(RouteStatus).filter(s => s !== status) // we can't update status to the same value
    const condition = { couchdb_id: routeIds, status: possibleStatuses }
    const now = new Date().toJSON()
    const data = {
      status,
      ...(
        status === RouteStatus.APPROVED
          ? {
            approval_date: now,
            estimated_delivery_date: addDays(now, ESTIMATED_DELIVERY_DATE_DAYS),
            estimated_pickup_date: addHours(now, ESTIMATED_PICKUP_DATE_HOURS)
          }
          : {}
      )
    }

    return this.update({condition, data})
  }

  async planRoutesManually ({ bufferData, startDate, endDate }) {
    const SHEET_NAME = 'Warehouse shipment export'
    const workbook = xlsx.read(bufferData, {type: 'array'})
    const allocationSheet = workbook.Sheets[SHEET_NAME]
    if (!allocationSheet) {
      throw new Error(`Worksheet '${SHEET_NAME}' not found`)
    }
    const rows = xlsx.utils.sheet_to_json(allocationSheet)

    // Check for the presence of suborderid in all rows
    const missingSuborderIds = []
    rows.forEach((row, index) => {
      if (!row.subOrderId) {
        missingSuborderIds.push(row.locationCode)
      }
    })

    if (missingSuborderIds.length > 0) {
      throw new Error(` ${missingSuborderIds.join(', ')} missing subOrderId`)
    }

    const data = rows.reduce((acc, row) => {
      const { route: name, subOrderId, shipmentId } = row
      if (!acc[name]) {
        return {
          ...acc,
          [name]: {
            name,
            subOrderIds: [subOrderId],
            shipmentIds: [shipmentId],
            startDate,
            endDate
          }
        }
      }
      return {
        ...acc,
        [name]: {
          name,
          subOrderIds: [...acc[name].subOrderIds, subOrderId],
          shipmentIds: [...acc[name].shipmentIds, shipmentId],
          startDate,
          endDate
        }
      }
    }, {})

    return this.bulkCreate(Object.values(data))
  }

  /**
    * Bulk create routes
    * @param {Array} routeData
    */
  async bulkCreate (routeInputs) {
    const routeData = routeInputs.map(route => {
      if (!route) {
        throw new Error('Route is undefined')
      }
      const {
        shipmentIds,
        subOrderIds,
        ...rest
      } = route

      return rest
    })

    const routeEntries = await this.create({ routeData })

    const routesByName = keyBy(routeEntries, 'name')

    const relationUpdatePromises = routeInputs.reduce((acc, route) => {
      const {
        shipmentIds,
        // subOrderIds,
        name
      } = route

      const routeId = routesByName[name].couchdb_id
      return [...acc, this.mainApi.shipment.updateRoute({ shipmentIds, routeId })]
    }, [])
    return Promise.all(relationUpdatePromises)
  }

  async list (filter, { isOnline } = {isOnline: false}) {
    const getRoutesRequester = (isOnline) => {
      return isOnline ? (
        this.agaveAdapter
          ? (filter) => {
            const snakeCaseFilter = Object.keys(filter).reduce((acc, key) => {
              acc[snakeCase(key)] = filter[key]
              return acc
            }, {})
            return this.agaveAdapter.list(entityName, snakeCaseFilter)
          }
          : (filter) => this.pgDataAdapter.getList({ filter })
      )
        : (filter) => api.list(this.state, filter)
    }
    try {
      return getRoutesRequester(isOnline)(filter)
    } catch (e) {
      console.error('Error fetching routes by dates', e)
      return []
    }
  }

  async get (routeId, { isOnline } = {isOnline: false}) {
    const getRouteRequester = (isOnline) => {
      return isOnline ? (
        this.agaveAdapter
          ? (routeId) => {
            return this.agaveAdapter.list(`${entityName}/${routeId}`)
          }
          : (routeId) => this.pgDataAdapter.getOne(routeId, { whereCondition: 'couchdb_id' })
      )
        : (routeId) => api.get(this.state, routeId)
    }
    try {
      return getRouteRequester(isOnline)(routeId)
    } catch (e) {
      console.error('Error fetching routes by dates', e)
      return []
    }
  }

  async create ({ routeData }) {
    const actualRoutes = Array.isArray(routeData) ? routeData : [ routeData ]

    return this.pgDataAdapter.create(
      actualRoutes.map(route => {
        const id = uuid()
        return {
          status: RouteStatus.PROVISIONAL,
          id,
          couchdb_id: `route:${id}`,
          start_date: route.startDate,
          end_date: route.endDate,
          ...route
        }
      })
    )
  }

  async update ({ condition, data }) {
    return this.pgDataAdapter.updateWhere({filter: condition}, data)
  }

  bulkImportLocationRoutes (routes) {
    return this.agaveAdapter.post('location_routes/bulk-save', routes)
  }

  async syncToRDS (routeId) {
    let couchDBRoute
    try {
      couchDBRoute = await this.get(routeId)
    } catch (e) {
      console.error('Error fetching route from couchDB', e)
      throw e
    }
    // The only possible operation is to create new routes,
    // no to update or delete them.
    // The reason not to update is to keep the a clean
    // history of changes, route assignments change but
    // routes do not.
    // Keeping routes that have been deleted in couchDB
    // can also be useful to make sense of route assignment
    // history
    const rdsRoute = toRelationalModel(couchDBRoute)
    return this.pgDataAdapter.upsert(rdsRoute)
  }

  listDeliveryDates (service, refDate) {
    return this.adapter.listDeliveryDates(service, refDate)
  }

  listCountDates (service, refDate) {
    return this.adapter.listCountDates(service, refDate)
  }
}

Object.assign(
  RoutesApi.prototype,
  {
    isRouteEditable,
    RouteStatus
  }
)

module.exports = rawMethods
module.exports.RoutesApi = RoutesApi
