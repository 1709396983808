const normaliseUser = require('./../../../utils/normalise-user')

exports.list = async function (state, params) {
  const users = await state.dal.user.getAll(state, params)
  return users.map(normaliseUser)
}

exports.listUsersByRole = async function (state, { userRole, userOrganisation }) {
  const users = await state.dal.user.listUsersByRole(state, { userRole, userOrganisation })
  return users.map(normaliseUser)
}
