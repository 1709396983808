import React, { useState } from 'react'
import ShipmentExportImport from './PSMShipmentExportImport'
import { toast } from '@fielded/shared-ui/src/components/Page'

const PSMShipmentExportImportContainer = () => {
  const [loading, setLoading] = useState(false)

  const onImport = () => {
    setLoading(true)
  }

  const onImported = (data) => {
    console.log(data)
    try {
      // handle data
    } catch (error) {
      // handle error
    } finally {
      setLoading(false)
    }
  }

  const onError = (error) => {
    console.log(error)

    toast({
      title: 'Something went wrong',
      type: 'warning',
      error: true,
      autoDismiss: false
    })
  }

  return (
    <ShipmentExportImport
      loading={loading}
      onImport={onImport}
      onImported={onImported}
      onError={onError}
    />
  )
}

export default PSMShipmentExportImportContainer
