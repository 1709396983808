const PGAdapter = require('../../common/pg-adapter')
const { DATABASE, COLUMNS, SQL } = require('./constants')

class CarrierPGAdapter extends PGAdapter {
  constructor (logger, pgConnection, username) {
    super(
      pgConnection,
      `${DATABASE.SCHEMA}.${DATABASE.TABLES.CARRIER}`,
      username,
      COLUMNS.carrier,
      'organisation_id',
      {},
      logger
    )
  }

  async create (organisationId, client = this.pgConnection) {
    const { rows: [carrier] } = await client.query(
      SQL.CREATE_CARRIER,
      [organisationId]
    )
    return carrier
  }
}

module.exports = CarrierPGAdapter
