const { create } = require('./write/create')
const { getById } = require('./read/get-by-id')
const { list } = require('./read/list')
const { update } = require('./write/update')

module.exports = {
  create,
  getById,
  list,
  update
}
