const PGAdapter = require('../../common/pg-adapter')
const { DATABASE, COLUMNS, SQL } = require('./constants')
const { v4: uuidv4 } = require('uuid')

class OperationalStatePGAdapter extends PGAdapter {
  constructor (logger, pgConnection, username) {
    super(
      pgConnection,
      `${DATABASE.SCHEMA}.${DATABASE.TABLES.OPERATIONAL_STATE}`,
      username,
      COLUMNS.operationalState,
      'id',
      {},
      logger
    )
  }

  async create (id, opState, dates, client = this.pgConnection) {
    const { rows: [operationalState] } = await client.query(SQL.CREATE_OPERATIONAL_STATE, [
      uuidv4(),
      id,
      opState.state,
      opState.priority,
      dates.created_at,
      dates.updated_at
    ])
    return operationalState
  }

  async update (carrierId, opState, dates, client = this.pgConnection) {
    const { rows: operationalState } = await client.query(SQL.UPDATE_OPERATIONAL_STATE, [
      Array(opState.length).fill().map(() => uuidv4()),
      carrierId,
      opState.map(s => s.state),
      opState.map(s => s.priority),
      dates.created_at,
      dates.updated_at
    ])
    return operationalState
  }

  async delete (id, client = this.pgConnection) {
    await client.query(SQL.DELETE_OPERATIONAL_STATE, [id])
  }
}

module.exports = OperationalStatePGAdapter
