/*
 * This is like the document container,
 * but for documents that are tied to the user,
 * not to the location
 */
import React, { Component } from 'react'
import get from 'lodash/get'

import { Loading, Page } from '@fielded/shared-ui'

import { withApi } from '../../../common/ApiProvider'
import { withUser } from '../../../common/AuthenticationProvider'
import settingsBreadcrumb from '../common/settingsBreadcrumb'
import { getDocumentVersionId } from '../common/utils'
import FPAgreement from '../components/Documents/FPAgreement'
import { locationIdToProperties as toLocationProperties } from '@fielded/fs-api/lib/tools'

const documents = {
  'fp-agreement': {
    title: 'Field Intelligence Fulfillment Partner Master Service Agreement',
    currentVersion: 'v1.0.0',
    component: FPAgreement
  }
}

class DocumentContainer extends Component {
  state = {
    loaded: false,
    program: {}
  }

  async componentDidMount () {
    const {
      match: {
        params: {
          documentSlug
        }
      },
      api,
      user
    } = this.props

    try {
      // Just to get local contact details
      const service = await api.service.get('program:shelflife:service:pharmaceuticals')
      const signEvent = await api.document.getSignEvent({
        userId: user._id,
        documentId: documentSlug
      })

      let latestDocumentVersionId
      // if the document is already signed we don't care about what's the latest version of the doc for the
      // client location (we only care about the version the client already signed)
      if (!signEvent) {
        const document = documents[documentSlug]
        latestDocumentVersionId = await getDocumentVersionId(documentSlug, user.location.id, document.currentVersion)
      }

      this.setState({
        loaded: true,
        signEvent,
        latestDocumentVersionId,
        program: service.program
      })
    } catch (err) {
      return this.setState({
        loaded: true,
        errorMessage: err.message
      })
    }
  }

  signDocument = async (data) => {
    const {
      match: {
        params: {
          documentSlug
        }
      },
      api,
      user
    } = this.props
    const { latestDocumentVersionId } = this.state

    const signEvent = await api.document.signDocument({
      userId: user._id,
      documentId: documentSlug,
      documentVersion: latestDocumentVersionId,
      data
    })

    this.setState({
      signEvent
    })
  }

  render () {
    const { history, user } = this.props
    const { documentSlug } = this.props.match.params

    const {
      loaded,
      signEvent,
      latestDocumentVersionId
    } = this.state

    const document = documents[documentSlug]
    const documentTitle = get(document, 'title')

    if (!loaded) {
      return <Loading loadingText='Loading document …' />
    }

    const location = toLocationProperties(user.location.id)

    const breadcrumbs = this.props.breadcrumbs || [{
      icon: settingsBreadcrumb.icon,
      title: 'Back to Profile',
      path: '/profile'
    }]

    return (
      <Page
        title={`Shelf Life - ${documentTitle}`}
        className='sl-print-document'
        printTemplate='SL'
        location={location}
      >
        <Page.Header
          className='vs-u-hide-on-print'
          title={documentTitle}
          breadcrumbItems={breadcrumbs}
        />
        {document
          ? (
            <document.component
              history={history}
              documentVersionId={signEvent ? signEvent.documentVersion : latestDocumentVersionId}
              signEvent={signEvent}
              signDocument={signEvent ? () => {} : this.signDocument}
            />
          ) : (
            <Page.Panel>
              Document not found
            </Page.Panel>
          )
        }
      </Page>
    )
  }
}

export default withApi(withUser(DocumentContainer))

export { DocumentContainer }
