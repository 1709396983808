const PGAdapter = require('../../common/pg-adapter')
const CarrierPGAdapter = require('./carrier-pg-adapter')
const FleetCategoryPGAdapter = require('./fleet-pg-adapter')
const OperationalStatePGAdapter = require('./operationalstate-pg-adapter')
const { DATABASE, SQL, COLUMNS } = require('./constants')
const { v4: uuidv4 } = require('uuid')

class OrganisationPGAdapter extends PGAdapter {
  constructor (logger, pgConnection, username) {
    super(
      pgConnection,
      `${DATABASE.SCHEMA}.${DATABASE.TABLES.ORGANISATION}`,
      username,
      COLUMNS.organisation,
      'id',
      {},
      logger
    )
    this.logger = logger
    this.carrier = new CarrierPGAdapter(logger, pgConnection, username)
    this.fleetCategory = new FleetCategoryPGAdapter(logger, pgConnection, username)
    this.operationalState = new OperationalStatePGAdapter(logger, pgConnection, username)
  }

  async getById (id) {
    const { rows } = await this.pgConnection.query(SQL.GET_ORGANISATION, [id])
    return rows[0] ? rows[0].data : null
  }

  async create (data) {
    const client = await this.pgConnection
    try {
      await client.query('BEGIN')
      const { organisation, fleetCategories, operationalStates, dates } = data

      // Create organisation
      const orgValues = {
        id: uuidv4(),
        ...organisation,
        email_addresses: JSON.stringify(organisation.email_addresses),
        created_at: dates.created_at,
        updated_at: dates.updated_at
      }
      const org = await super.create(orgValues)

      // Create carrier
      const carrier = await this.carrier.create(org.id, client)

      // Create fleet categories
      if (fleetCategories && fleetCategories.length) {
        await Promise.all(fleetCategories.map(fleet =>
          this.fleetCategory.create(carrier.organisation_id, fleet, dates, client)
        ))
      }

      // Create operational state
      if (operationalStates && operationalStates.length) {
        for (const opState of operationalStates) {
          await this.operationalState.create(
            carrier.organisation_id,
            opState,
            dates,
            client
          )
        }
      }

      await client.query('COMMIT')
      return this.getById(org.id)
    } catch (error) {
      await client.query('ROLLBACK')
      throw error
    }
  }

  async list () {
    const { rows } = await this.pgConnection.query(SQL.LIST_ORGANISATIONS)
    const result = rows.map(row => row.data)
    return result[0]
  }

  async update (id, data) {
    const client = await this.pgConnection
    try {
      await client.query('BEGIN')
      const { organisation, fleetCategories, operationalStates, dates } = data

      // Update organisation
      const { rows: [org] } = await client.query(
        SQL.UPDATE_ORGANISATION,
        [
          id,
          organisation.name,
          organisation.phone_number,
          JSON.stringify(organisation.email_addresses),
          organisation.country,
          organisation.state,
          organisation.lga,
          organisation.city,
          organisation.street,
          dates.updated_at
        ]
      )

      const createdAt = org.created_at
      const updatedDates = {
        ...dates,
        created_at: createdAt
      }

      // Update fleet categories
      if (fleetCategories && fleetCategories.length) {
        await this.fleetCategory.update(org.id, fleetCategories, updatedDates, client)
      }

      // Update operational state
      if (operationalStates && operationalStates.length) {
        await this.operationalState.delete(org.id, client)
        for (const opState of operationalStates) {
          await this.operationalState.create(org.id, opState, updatedDates, client)
        }
      }

      await client.query('COMMIT')
      return this.getById(id)
    } catch (error) {
      await client.query('ROLLBACK')
      throw error
    }
  }
}

module.exports = OrganisationPGAdapter
