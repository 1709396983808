export const COLUMNS = [
  {
    key: 'id',
    header: 'id',
    dataKey: 'id',
    disableSort: true
  },
  {
    key: 'product',
    header: 'product',
    dataKey: 'product',
    disableSort: true
  },
  {
    key: 'quantity',
    header: 'quantity',
    dataKey: 'quantity',
    disableSort: true
  },
  {
    key: 'origin',
    header: 'origin',
    dataKey: 'origin',
    disableSort: true
  },
  {
    key: 'externalId',
    header: 'external Id',
    dataKey: 'externalId',
    disableSort: true
  }
]

export const ROWS = [
  {
    id: 'zone:north-central:state:fct:lga:abaji:sdp:primary-health-centre-mamagi',
    product: 'ghsc-fp0001',
    quantity: '300',
    origin: 'zone:north-central:name:abuja-usaid',
    externalId: '001'
  },
  {
    id: 'zone:north-central:state:fct:lga:abaji:sdp:maternal-and-child-health',
    product: 'ghsc-aid0146',
    quantity: '100',
    origin: 'zone:north-central:name:abuja-global-fund',
    externalId: '002'
  }
]
