const uuid = require('uuid')
const DataAdapterInterface = require('./data-adapter-interface')
const isPGUsageErrorCode = require('./isPGUsageErrorCode')
const {getWhereClause, getOrderByClause, getColumnsUniqueValuesQuery} = require('../tools/sql-tools')

/*
  Helper adapter for sql CRUD methods with a field-style adapter interface.
  If these methods don't fit a use case, please don't hesitate to write custom sql
  in your entity's pg adapter, or bring it up in a tech meeting.
*/
class PGAdapter extends DataAdapterInterface {
  constructor (pgConnection, tableName, username, columns, idColumn = 'id', columnPreparers, logger, opts = {strictColumns: true, addUuid: true}) {
    super()
    if (
      typeof pgConnection !== 'object' ||
      !tableName ||
      typeof tableName !== 'string'
    ) {
      logger.info(`PGAdapter ${tableName} initialised without a connection. Will not persist.`)
    }
    const columnsSet = new Set([ idColumn, ...columns || [] ])
    this.columns = Array.from(columnsSet)
    this.virtualColumns = opts.virtualColumns || []
    this.searchColumns = opts.searchColumns || []

    this.pgConnection = pgConnection
    this.tableName = tableName
    this.idColumn = idColumn
    this.username = username
    this.columnPreparers = columnPreparers
    this.logger = logger
    this.strictColumns = !!opts.strictColumns
    this.getRelated = opts.getRelated
    this.addUuid = opts.addUuid
  }

  getWhereCondition (filter, search) {
    const searchOpts = search && {
      text: search,
      columns: this.searchColumns
    }
    const cleanedFilter = Object
      .keys(filter)
      .filter(k => this.columns.concat(this.virtualColumns).includes(k))
      .reduce((a, c) => { a[c] = filter[c]; return a }, {})
    return getWhereClause({filter: cleanedFilter, preparers: this.columnPreparers, search: searchOpts})
  }

  applyCreateDefaults (data) {
    // TODO: UUID should happen in postgresql. Currently it doesn't, because
    // our models were created by Django which does auto uuid at
    // the python django layer.
    // Maybe when we switch to running our own sql migrations we make sure uuid
    // auto happens in sql
    if (!data[this.idColumn] && this.columns.includes(this.idColumn) && this.addUuid) {
      data[this.idColumn] = uuid.v4()
    }
    if (!data.created_at && this.columns.includes('created_at')) {
      data.created_at = new Date().toJSON()
    }
    if (!data.created_by && this.columns.includes('created_by')) {
      data.created_by = this.username
    }

    this.applyUpdateDefaults(data)
  }

  applyUpdateDefaults (data) {
    if (!data.updated_at && this.columns.includes('updated_at')) {
      data.updated_at = new Date().toJSON()
    }
    if (!data.updated_by && this.columns.includes('updated_by')) {
      data.updated_by = this.username
    }
  }

  /*
    Get records from a table on which you can sort, filter, limit and offset.
    Example 1: get second page of newest FCT locations: getList({
      sort: '-created_at',  filter: {market: 'fct'}, limit: 50,  offset: 50
    })
    Example 2 get everything in the table: getList({limit: Number.MAX_SAFE_INTEGER})

    Returns a count of all the retrieved rows. That may be skipped if unneeded by providing skipCount: true as a parameter
  */
  async getList ({
    ordering = this.idColumn,
    filter = {},
    limit = 50,
    offset = 0,
    tableExpression = null,
    search = null,
    skipCount = false,
    getRelated
  } = {}) {
    /*
      pg and params (https://node-postgres.com/features/queries):

      > "If you are passing parameters to your queries you will want to avoid string
       concatenating parameters into the query text directly.
       This can (and often does) lead to sql injection vulnerabilities.
       node-postgres supports parameterized queries, passing your query text unaltered as
       well as your parameters to the PostgreSQL server where the parameters are safely
       substituted into the query with battle-tested parameter substitution code within
       the server itself."

      So here we can trust `tableName` and sortDirection because they are hard coded.
      But everything else must be in the params array to avoid sql injection.
    */
    //  https://github.com/brianc/node-postgres/issues/300#issuecomment-14631717
    // https://stackoverflow.com/questions/41455585/does-pg-node-postgres-automatically-sanitize-data

    const {whereText, whereValues} = this.getWhereCondition(filter, search)
    const sortText = getOrderByClause(ordering, this.columns.concat(this.virtualColumns))
    /*
      Postgresql usage reminder ():
      > ASC order is the default. Ascending order puts smaller values first,
      where “smaller” is defined in terms of the < operator.
      - https://www.postgresql.org/docs/13/queries-order.html
    */
    const values = whereValues.concat([limit, offset])

    const text = `SELECT * FROM ${tableExpression || this.tableName} ${whereText} ${sortText} LIMIT $${values.length - 1} OFFSET $${values.length};`
    let { rows: results } = await this.pgConnection.query({ text, values })

    const relatedEntitiesConfig = getRelated || this.getRelated
    if (relatedEntitiesConfig) {
      results = await this.addCollectionRecords(results, relatedEntitiesConfig)
    }

    const resultList = { results }

    if (!skipCount) { // Sometimes we don't need it so we may skip it for performance reasons
      resultList.count = await this.getCount(undefined, whereText, whereValues, tableExpression)
    }

    return resultList
  }

  async addCollectionRecords (results, getRelated) {
    for (let k in getRelated) {
      let collection = getRelated[k]
      const pkField = collection.pk || this.idColumn
      const pkValues = results.map(r => r[pkField])
      const fields = (collection.fields && collection.fields.length && `${collection.fk},${collection.fields.join(',')}`) || '*'
      // get all the related records from the db
      const text = `SELECT ${fields} FROM ${collection.relatedTable} WHERE ${collection.fk} = ANY ($1)`
      let { rows: relatedResults } = await this.pgConnection.query(text, [pkValues])

      // add related objects to matching primary results
      for (let related of relatedResults) {
        for (let primary of results) {
          if (related[collection.fk] === primary[pkField]) {
            primary[k] = (primary[k] || []).concat([related])
          }
        }
      }
    }
    return results
  }

  async getCount (column, whereText, whereValues, tableExpression) {
    column = column || this.idColumn
    // https://stackoverflow.com/questions/7943233/fast-way-to-discover-the-row-count-of-a-table-in-postgresql
    // you can get an estimate, or you can get a slow count :/ count(*) is worse
    const countResponse = await this.pgConnection.query({text: `SELECT COUNT(${column}) FROM ${tableExpression || this.tableName} ${whereText}`, values: whereValues})
    return Number(countResponse.rows[0].count)
  }
  async getColumnsUniqueValues (columns = this.columns, calculatedColumns = [], filter, tableExpression = this.tableName, preparers = {}) {
    const {query: text, params: values} = getColumnsUniqueValuesQuery(tableExpression, columns, calculatedColumns, filter, preparers)
    return this.pgConnection.query({text, values})
  }

  async getOne (id, { whereCondition = this.idColumn, tableExpression } = {}) {
    let row
    try {
      const {rows} = await this.pgConnection.query(`SELECT * FROM ${tableExpression || this.tableName} WHERE ${whereCondition} = $1`, [id])
      row = rows[0]
    } catch (err) {
      if (isPGUsageErrorCode(err.code)) {
        err.status = 400
      }
      throw err
    }
    if (!row) {
      const err = new Error('Row not found')
      err.status = 404
      throw err
    }
    if (this.getRelated) {
      const results = await this.addCollectionRecords([row], this.getRelated)
      row = results[0]
    }
    return row
  }

  async deleteWhere (condition, { client } = { client: this.pgConnection }) {
    if (typeof condition !== 'object') {
      throw new Error('PGAdapter Usage: deleteWhere expects condition object')
    }
    const whereClause = getWhereClause({
      ...condition,
      preparers: {
        ...condition.preparers,
        ...this.columnPreparers
      }
    })
    try {
      const returning = await client.query(`DELETE FROM ${this.tableName} ${whereClause.whereText} RETURNING *;`, whereClause.whereValues)
      return returning.rows
    } catch (err) {
      if (isPGUsageErrorCode(err.code)) {
        err.status = 400
      }
      throw err
    }
  }

  async delete (id) {
    return this.deleteWhere({ filter: { [this.idColumn]: id } })
  }

  async create (data) {
    if (Array.isArray(data)) {
      return Promise.all(data.map(d => this.create(d)))
    }

    if (typeof data !== 'object' || Array.isArray(data)) {
      throw new Error('PGAdapter Usage: create expects object param')
    }

    this.applyCreateDefaults(data)

    if (this.strictColumns) {
      const columnsNotFound = Object.keys(data).filter(key => !this.columns.includes(key))
      if (columnsNotFound.length) {
        throw new Error(`create received additional column(s): ${columnsNotFound.join(',')}`)
      }
    }

    const requestColumns = Object.keys(data).filter(k => this.columns.includes(k))
    const values = requestColumns.map(key => data[key])
    const parametrizedString = values.map((_, idx) => `$${idx + 1}`).join(',')
    const text = `INSERT INTO ${this.tableName}(${requestColumns.join(',')}) VALUES (${parametrizedString}) RETURNING *;`

    try {
      const returning = await this.pgConnection.query({
        text,
        values
      })
      return returning.rows[0]
    } catch (err) {
      if (isPGUsageErrorCode(err.code)) {
        err.status = 400
      }
      throw err
    }
  }

  async upsert (data, { conflictIdColumns, client } = {}) {
    if (!conflictIdColumns) conflictIdColumns = [this.idColumn]
    if (!client) client = this.pgConnection

    if (Array.isArray(data)) {
      return Promise.all(data.map(d => this.upsert(d, { conflictIdColumns, client })))
    }

    if (typeof data !== 'object' || Array.isArray(data)) {
      throw new Error('PGAdapter Usage: update expects object param')
    }

    const conflictIdValues = conflictIdColumns.map(c => data[c]).filter(Boolean)

    if (conflictIdValues.length !== conflictIdColumns.length) {
      throw new Error(`value for conflictIdColumns (${conflictIdColumns.join(',')}) is missing: ${JSON.stringify(data)}`)
    }

    this.applyCreateDefaults(data)

    const columnsNotFound = Object.keys(data).filter(key => !this.columns.includes(key))
    if (this.strictColumns && columnsNotFound.length) {
      throw new Error(`create received additional column(s): ${columnsNotFound.join(',')}`)
    }

    const requestColumns = Object.keys(data).filter(k => this.columns.includes(k))
    const values = requestColumns.map(key => data[key])
    const parametrizedString = values.map((_, idx) => `$${idx + 1}`).join(',')
    const exlucedColumnsOnConflict = ['created_at', 'created_by', this.idColumn].concat(conflictIdColumns)
    const setExcludedString = requestColumns
      .filter(key => exlucedColumnsOnConflict.indexOf(key) === -1)
      .map((key) => `${key} = EXCLUDED.${key}`).join(',')
    const text = `INSERT INTO ${this.tableName}(${requestColumns.join(',')})
        VALUES (${parametrizedString})
        ON CONFLICT (${conflictIdColumns.join(',')}) DO UPDATE
            SET ${setExcludedString}
        RETURNING *;`

    try {
      const returning = await client.query({
        text,
        values
      })
      return returning.rows[0]
    } catch (err) {
      if (isPGUsageErrorCode(err.code)) {
        err.status = 400
      }
      throw err
    }
  }

  async update (data) {
    if (Array.isArray(data)) {
      return Promise.all(data.map(d => this.update(d)))
    }

    const idValue = data[this.idColumn]

    if (!idValue) {
      throw new Error(`value for idColumn (${this.idColumn}) is missing: ${JSON.stringify(data)}`)
    }

    const updateData = { ...data }
    delete updateData[this.idColumn]

    const condition = {
      filter: {
        [this.idColumn]: idValue
      }
    }

    const result = await this.updateWhere(condition, updateData)
    return result[0]
  }

  // update columns for rows matching condition
  async updateWhere (condition, data, { client } = { client: this.pgConnection }) {
    if (typeof condition !== 'object') {
      throw new Error('PGAdapter Usage: updateWhere expects condition object')
    }
    if (typeof data !== 'object') {
      throw new Error('PGAdapter Usage: updateWhere expects data object')
    }

    this.applyUpdateDefaults(data)

    const columnsNotFound = Object.keys(data).filter(key => !this.columns.includes(key))
    if (this.strictColumns && columnsNotFound.length) {
      throw new Error(`updateWhere received additional column(s): ${columnsNotFound.join(',')}`)
    }
    const requestColumns = Object.keys(data).filter(k => this.columns.includes(k))

    const props = []
    const updates = []
    let position = 1
    requestColumns.forEach(key => {
      props.push(data[key])
      updates.push(`${key} = $${position}`)
      position++
    })

    const whereClause = getWhereClause({
      ...condition,
      preparers: {
        ...condition.preparers,
        ...this.columnPreparers
      },
      positionalArgumentOffset: position - 1
    })
    const updateText = updates.join(',')
    const text = `UPDATE ${this.tableName} SET ${updateText} ${whereClause.whereText} RETURNING *;`
    try {
      const updatedData = await this.pgConnection.query(text, [...props, ...whereClause.whereValues])
      return updatedData.rows
    } catch (err) {
      if (isPGUsageErrorCode(err.code)) {
        err.status = 400
      }
      throw err
    }
  }
}

module.exports = PGAdapter
