module.exports = stockCountIdToLocationProperties

const { locationIdToProperties } = require('./location-id-to-properties')
const { isV1Id, isV2Id } = require('../report/tools/ids')

function stockCountIdToLocationProperties (id) {
  let locationId
  if (id.startsWith('national')) {
    locationId = 'national'
  } else if (isV1Id(id)) {
    // The id will contain a period, e.g. "week:2017-W01" somewhere and we
    // need to remove that. The rest will be the location properties.
    const [, part1, part2] = id.match(/^(.+)(?:(?:week|bimonth|month):[0-9]{4}-.[0-9]{1,2}:?)(.*)$/)
    locationId = part1 + part2
  } else if (isV2Id(id)) {
    // This is a v2 id. It looks like zone:ne:state:borno:program:malaria:service:malaria:period:2018-M01...
    locationId = id.slice(0, id.indexOf(':program:'))
  } else {
    throw new Error('Unknown id structure')
  }
  return locationIdToProperties(locationId)
}
