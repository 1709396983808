const DATABASE = {
  SCHEMA: 'avocado',
  TABLES: {
    ORGANISATION: 'data_organisation',
    CARRIER: 'data_carrier',
    FLEET_CATEGORY: 'data_fleetcategory',
    OPERATIONAL_STATE: 'data_operationalstate'
  }
}

const COLUMNS = {
  organisation: [
    'id',
    'name',
    'phone_number',
    'email_addresses',
    'country',
    'state',
    'lga',
    'city',
    'street',
    'created_at',
    'updated_at'
  ],
  carrier: [
    'organisation_id'
  ],
  fleetCategory: [
    'id',
    'carrier_id',
    'category',
    'comment',
    'created_at',
    'updated_at'
  ],
  operationalState: [
    'id',
    'carrier_id',
    'state',
    'priority',
    'created_at',
    'updated_at'
  ]
}

const SQL = {
  CREATE_ORGANISATION: `
    INSERT INTO ${DATABASE.SCHEMA}.${DATABASE.TABLES.ORGANISATION}
    (${COLUMNS.organisation.join(', ')})
    VALUES (${COLUMNS.organisation.map((_, i) => `$${i + 1}`).join(', ')})
    RETURNING *
  `,

  UPDATE_ORGANISATION: `
    UPDATE ${DATABASE.SCHEMA}.${DATABASE.TABLES.ORGANISATION}
    SET name = $2, phone_number = $3, email_addresses = $4, 
        country = $5, state = $6, lga = $7, city = $8, street = $9, updated_at = $10
    WHERE id = $1
    RETURNING *
  `,

  CREATE_CARRIER: `
    INSERT INTO ${DATABASE.SCHEMA}.${DATABASE.TABLES.CARRIER}
    (${COLUMNS.carrier.join(', ')})
    VALUES (${COLUMNS.carrier.map((_, i) => `$${i + 1}`).join(', ')})
    RETURNING *
  `,

  CREATE_FLEET_CATEGORY: `
    INSERT INTO ${DATABASE.SCHEMA}.${DATABASE.TABLES.FLEET_CATEGORY}
    (${COLUMNS.fleetCategory.join(', ')})
    VALUES (${COLUMNS.fleetCategory.map((_, i) => `$${i + 1}`).join(', ')})
    ON CONFLICT ON CONSTRAINT unique_carrier_category
    DO UPDATE SET
      comment = EXCLUDED.comment,
      updated_at = EXCLUDED.updated_at
    RETURNING *
  `,

  UPDATE_FLEET_CATEGORIES: `
    WITH new_fleet AS (
      SELECT 
        unnest($1::uuid[]) as id,
        $2::uuid as carrier_id,
        unnest($3::text[]) as category,
        unnest($4::text[]) as comment,
        $5::timestamp as created_at,
        $6::timestamp as updated_at
    ),
    delete_old AS (
      DELETE FROM ${DATABASE.SCHEMA}.${DATABASE.TABLES.FLEET_CATEGORY}
      WHERE carrier_id = $2
      AND category NOT IN (SELECT category FROM new_fleet)
    )
    INSERT INTO ${DATABASE.SCHEMA}.${DATABASE.TABLES.FLEET_CATEGORY}
    (id, carrier_id, category, comment, created_at, updated_at)
    SELECT 
      COALESCE(fc.id, nf.id),
      nf.carrier_id,
      nf.category,
      nf.comment,
      COALESCE(fc.created_at, nf.created_at),
      nf.updated_at
    FROM new_fleet nf
    LEFT JOIN ${DATABASE.SCHEMA}.${DATABASE.TABLES.FLEET_CATEGORY} fc
      ON fc.carrier_id = nf.carrier_id 
      AND fc.category = nf.category
    ON CONFLICT ON CONSTRAINT unique_carrier_category
    DO UPDATE SET
      comment = EXCLUDED.comment,
      updated_at = EXCLUDED.updated_at
    RETURNING *
  `,

  DELETE_FLEET_CATEGORY: `
    DELETE FROM ${DATABASE.SCHEMA}.${DATABASE.TABLES.FLEET_CATEGORY}
    WHERE carrier_id = $1
  `,

  CREATE_OPERATIONAL_STATE: `
    INSERT INTO ${DATABASE.SCHEMA}.${DATABASE.TABLES.OPERATIONAL_STATE}
    (${COLUMNS.operationalState.join(', ')})
    VALUES (${COLUMNS.operationalState.map((_, i) => `$${i + 1}`).join(', ')})
    ON CONFLICT ON CONSTRAINT unique_carrier_state DO UPDATE 
    SET state = EXCLUDED.state,
        priority = EXCLUDED.priority,
        updated_at = now()
    RETURNING *
  `,

  DELETE_OPERATIONAL_STATE: `
    DELETE FROM ${DATABASE.SCHEMA}.${DATABASE.TABLES.OPERATIONAL_STATE}
    WHERE carrier_id = $1
  `,

  GET_ORGANISATION: `
    WITH org AS (
      SELECT * FROM ${DATABASE.SCHEMA}.${DATABASE.TABLES.ORGANISATION} WHERE id = $1
    ),
    carr AS (
      SELECT * FROM ${DATABASE.SCHEMA}.${DATABASE.TABLES.CARRIER} WHERE organisation_id = $1
    ),
    fleet AS (
      SELECT * FROM ${DATABASE.SCHEMA}.${DATABASE.TABLES.FLEET_CATEGORY} 
      WHERE carrier_id = (SELECT organisation_id FROM carr)
    ),
    op_state AS (
      SELECT * FROM ${DATABASE.SCHEMA}.${DATABASE.TABLES.OPERATIONAL_STATE} 
      WHERE carrier_id = (SELECT organisation_id FROM carr)
    )
    SELECT json_build_object(
      'organisation', row_to_json(org.*),
      'carrier', row_to_json(carr.*),
      'fleetCategories', COALESCE(json_agg(DISTINCT fleet.*) FILTER (WHERE fleet.id IS NOT NULL), '[]'),
      'operationalStates', COALESCE(json_agg(DISTINCT op_state.*) FILTER (WHERE op_state.id IS NOT NULL), '[]')
    ) as data
    FROM org
    LEFT JOIN carr ON org.id = carr.organisation_id
    LEFT JOIN fleet ON carr.organisation_id = fleet.carrier_id
    LEFT JOIN op_state ON carr.organisation_id = op_state.carrier_id
    GROUP BY org.*, carr.*;
  `,

  LIST_ORGANISATIONS: `
    WITH org_data AS (
      SELECT 
        orgs.*,
        carr.organisation_id as carrier_id,
        json_build_object(
          'organisation', row_to_json(orgs.*),
          'carrier', row_to_json(carr.*),
          'fleetCategories', COALESCE(
            (
              SELECT json_agg(DISTINCT fleet.*) 
              FROM ${DATABASE.SCHEMA}.${DATABASE.TABLES.FLEET_CATEGORY} fleet
              WHERE fleet.carrier_id = carr.organisation_id
            ), 
            '[]'
          ),
          'operationalStates', COALESCE(
            (
              SELECT json_agg(DISTINCT op_state.*) 
              FROM ${DATABASE.SCHEMA}.${DATABASE.TABLES.OPERATIONAL_STATE} op_state
              WHERE op_state.carrier_id = carr.organisation_id
            ),
            '[]'
          )
        ) as org_json
      FROM ${DATABASE.SCHEMA}.${DATABASE.TABLES.ORGANISATION} orgs
      LEFT JOIN ${DATABASE.SCHEMA}.${DATABASE.TABLES.CARRIER} carr ON orgs.id = carr.organisation_id
    )
    SELECT json_build_object(
      'organisations', json_agg(org_json)
    ) as data
    FROM org_data;
  `
}

module.exports = {
  DATABASE,
  COLUMNS,
  SQL
}
