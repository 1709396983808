const { validateOrganisationData, validatePriorities } = require('../../tools/validation-tools')

exports.update = async (adapter, id, data) => {
  validateOrganisationData(data)
  if (data.operationalStates) {
    validatePriorities(data.operationalStates)
  }

  const organisation = await adapter.update(id, data)
  if (!organisation) {
    const error = new Error('Organisation not found')
    error.status = 404
    throw error
  }
  return organisation
}
