import { C_R } from '@fielded/fs-api/lib/shipment/tools/planning-types'
import { byId } from '../../../subapps/shipments/common/utils'

const reducerName = 'shipments'
const UPDATE_SHIPMENT = `${reducerName}/UPDATE_SHIPMENT`
const RECEIVE_SHIPMENTS = `${reducerName}/RECEIVE_SHIPMENTS`

const updateShipmentReducer = (state, action) => {
  const parentDocId = action.shipment.history[action.shipment.snapshotId].parentDocId
  let nextState = {...state}
  if (parentDocId) {
    const { [parentDocId]: omit, ...rest } = state
    nextState = rest
  }
  return {
    ...nextState,
    [action.shipment.snapshotId]: {
      ...action.shipment
    }
  }
}

const receiveShipments = (state, action) => ({
  ...byId(action.shipments, 'snapshotId')
})

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SHIPMENT: return updateShipmentReducer(state, action)
    case RECEIVE_SHIPMENTS: return receiveShipments(state, action)
    default: return state
  }
}

export const selectShipments = (state) => state.shipments

export const updateShipment = shipment => ({
  type: UPDATE_SHIPMENT,
  shipment
})

export const findShipments = (location, locationIsGeoLocation, hideNewShipmentsIfRouteSpecificUser, options) => async (dispatch, getState, { user, api }) => {
  // For PSM users asides from drivers and facility users get their shipments from postgress and do not need any extra computation here
  if (options && options.shouldUsePGShipments) {
    const { shipments } = await api.shipment.listLastMileDeliveries({ separateByCompletion: true })
    const allShipments = [...shipments.complete, ...shipments.incomplete]
    return dispatch({type: RECEIVE_SHIPMENTS, shipments: allShipments})
  }

  // This is kind of online-offline if you're calling it for a location that is not yours
  // but VAN does not expose that and SL does not use online offline
  // so there is no need to handle online-offline errors at the moment

  // By submitting a start date, the api will take the online path
  // for online-offline users, to load the entire history rather than
  // only what's offline available
  const startdate = (options && options.online) ? options.startdate || '2018-01-01' : null
  const promises = [
    api.shipment.find({ location, locationIsGeoLocation, startdate }, options)
  ]
  // If the location is a geo location we need to filter the shipments to only
  // include those that are valid locations for the users geo location. This way
  // we exclude shipments for locations that the user does not have the funder.
  // For that we need to get the locations the user can see.
  if (locationIsGeoLocation) {
    promises.push(
      api.location.listChildren(location.id, {includeSelf: true})
    )
  }
  return Promise.all(promises)
    .then(([shipments, locations]) => {
      // PSM Drivers and vendors/carriers are national users with specific routes
      // We filter here so we don't get external shipments
      if (options && options.noExternalShipments) {
        shipments = shipments.filter(s => !s.id.startsWith('origin:country:'))
      }
      if (locationIsGeoLocation) {
        const locationIdSet = new Set(locations.map(l => l._id))

        shipments = shipments.filter(
          s => locationIdSet.has(s.origin.id) || locationIdSet.has(s.destination.id)
        )
        // This is a ShelfLife driver (i.e. a "route specific user").
        // This filter here is not ideal, but the whole design around snapshots and the API
        // might change soon. Why not let api.shipment.find filter the shipments that are
        // relevant to the particular user?
        const identifier = user.routes ? user.routes.length : user.funders.length
        if (hideNewShipmentsIfRouteSpecificUser && identifier) {
          shipments = shipments.filter(s => {
            const notNew = s.status !== 'new'
            const createdBy = s.createdBy.user === user.name
            const isCR = s.planningType === C_R && s.status === 'new'
            return notNew || createdBy || isCR
          })
        }
      } else {
        const destinedShipments = shipments.filter(shipment => shipment.destination.id === location.id && shipment.status !== 'new')
        const originShipments = shipments.filter(shipment => shipment.origin.id === location.id)
        shipments = [...originShipments, ...destinedShipments]
      }
      dispatch({type: RECEIVE_SHIPMENTS, shipments})
    })
    .catch(err => {
      console.error('shipment loading error', err)
      throw err
    })
}
