module.exports = draftLocationIdToProperties

const { locationIdToProperties } = require('./location-id-to-properties')
const {
  toDraft,
  fromDraft
} = require('./utils/draft')

function draftLocationIdToProperties (draftId) {
  const id = fromDraft(draftId)
  const properties = locationIdToProperties(id)
  return Object.assign({}, properties, {
    id: toDraft(properties.id)
  })
}
