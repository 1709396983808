import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

import { Loading } from '@fielded/shared-ui'
import { PLANNING_TYPES } from '@fielded/fs-api/lib/shipment/tools'

import { makeDeliveryItems } from '../../common/makeDeliveryItems'
import { getLastSnapshotSignature, getLastSnapshotSignedBy } from '../../common/utils'
import { isReturnShipment } from '../../../../common/utils/shipment'

import { withApi } from '../../../../common/ApiProvider'

import { selectMasterData } from '../../../../root/reducers/shipments/master-data'
import SLDeliveryConfirmation from './SLDeliveryConfirmation'

const SLDeliveryConfirmationContainer = ({
  api,
  config,
  shipment,
  formRef,
  showTotalAmountConfirmation,
  onProceedClick,
  onCloseModal,
  onOpenModal,
  onCommentChange,
  comment
}) => {
  const masterData = useSelector(selectMasterData)
  const productsById = get(masterData, 'products.byId')

  const [items, setItems] = useState([])
  const [name, setName] = useState('')
  const [signature, setSignature] = useState('')
  const [location, setLocation] = useState()
  const [loading, setLoading] = useState(true)

  const initialize = async () => {
    let locationId = shipment.destination.id
    if (isReturnShipment(null, shipment)) {
      locationId = shipment.origin.id
    }

    const location = await api.location.get(locationId)

    const {service: serviceId} = api.service.tools.getServiceForLocationId(locationId)
    const service = await api.service.get(serviceId)

    const report = await api.stock.getLedgerBalanceAsReport({
      locationId,
      service,
      calculateFields: true
    })

    const items = makeDeliveryItems({ shipment, report, productsById }) || []

    const signature = getLastSnapshotSignature(shipment)
    const name = getLastSnapshotSignedBy(shipment)

    setItems(items)
    setSignature(signature)
    setName(name)
    setLocation(location)
    setLoading(false)
  }

  useEffect(() => {
    initialize()
  }, [])

  if (loading) {
    return <Loading loadingText='Loading delivery note …' />
  }

  const shipmentCountry = shipment.destination.country

  return (
    <SLDeliveryConfirmation
      location={location}
      shipment={shipment}
      productsById={productsById}
      items={items}
      shipmentCountry={shipmentCountry}
      signature={signature}
      config={config}
      formRef={formRef}
      onProceed={onProceedClick}
      onCloseModal={onCloseModal}
      onOpenModal={onOpenModal}
      comment={comment}
      name={name}
      onCommentChange={onCommentChange}
      showTotalAmountConfirmation={showTotalAmountConfirmation}
      isCollectRedistribute={shipment.planningType === PLANNING_TYPES.C_R}
    />
  )
}

export default withApi(SLDeliveryConfirmationContainer)
