const PRIORITY_CHOICES = ['primary', 'secondary', 'tertiary']
const REQUIRED_FIELDS = {
  organisation: ['name', 'phone_number', 'email_addresses', 'country', 'state', 'lga', 'city', 'street'],
  operationalState: ['state', 'priority']
}

function validateOrganisationData (data) {
  const { organisation, fleetCategories, operationalStates } = data

  // Required fields check
  const missingFields = REQUIRED_FIELDS.organisation.filter(
    field => !(organisation && organisation[field])
  )

  if (missingFields.length) {
    throw new Error(`Missing required fields: ${missingFields.join(', ')}`)
  }

  if (fleetCategories && !Array.isArray(fleetCategories)) {
    throw new Error('fleetCategories must be an array')
  }

  if (operationalStates) {
    if (!Array.isArray(operationalStates)) {
      throw new Error('operationalStates must be an array')
    }

    operationalStates.forEach(os => {
      const missingStateFields = REQUIRED_FIELDS.operationalState.filter(
        field => !os[field]
      )

      if (missingStateFields.length) {
        throw new Error(`Missing operational state fields: ${missingStateFields.join(', ')}`
        )
      }
    })
  }
}

function validatePriorities (operationalStates) {
  if (!(operationalStates && operationalStates.length)) return

  // Check for duplicate priorities
  const priorities = operationalStates.map(os => os.priority)
  const uniquePriorities = new Set(priorities)
  if (priorities.length !== uniquePriorities.size) {
    throw new Error('Duplicate priorities not allowed')
  }

  // Validate priority choices
  operationalStates.forEach(operationalState => {
    if (!PRIORITY_CHOICES.includes(operationalState.priority)) {
      throw new Error(`Invalid priority "${operationalState.priority}". Must be one of: ${PRIORITY_CHOICES.join(', ')}`)
    }
  })
}

module.exports = {
  validateOrganisationData,
  validatePriorities,
  PRIORITY_CHOICES
}
