import React from 'react'
import { Card, Page } from '@fielded/shared-ui'

const ExportImportCard = ({ title, description, children, button, extraButton }) => {
  return (
    <Page.Panel.Section>
      <Card tight>
        <Card.Header title={title}>
          {description}
        </Card.Header>
        {children && (
          <Card.Content>
            {children}
          </Card.Content>
        )}
        <Card.Action layout='left'>
          {button}
          {extraButton}
        </Card.Action>
      </Card>
    </Page.Panel.Section>
  )
}

export default ExportImportCard
