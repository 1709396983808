module.exports = denormaliseUser

const denormaliseRoles = require('./denormalise-roles')

function decorateUser (user, {defaultRole}) {
  if (!defaultRole) {
    throw new Error('options.defaultRole is required when saving a user')
  }

  const newRoles = global.JSON.parse(global.JSON.stringify(user.roles))
  if (!newRoles.includes(defaultRole)) {
    newRoles.push(defaultRole)
  }

  return Object.assign({}, user, {
    type: 'user',
    roles: newRoles
  })
}

function deconstructProfile (denormalisedUser, user) {
  let updatedUser = Object.assign({}, denormalisedUser)

  if (user.profile.email && user.profile.email !== '') {
    updatedUser = {
      ...updatedUser,
      email: user.profile.email
    }
  }

  if (user.profile.phone && user.profile.phone !== '') {
    updatedUser = {
      ...updatedUser,
      phone: user.profile.phone
    }
  }

  if (user.profile.fullName && user.profile.fullName !== '') {
    updatedUser = {
      ...updatedUser,
      fullName: user.profile.fullName
    }
  }

  return updatedUser
}

function denormaliseUser (user, options = {}) {
  const denormalisedUser = Object.keys(user).reduce((accumulator, prop) => {
    switch (prop) {
      case '_id':
      case '_rev':
      case 'derived_key':
      case 'iterations':
      case 'password_scheme':
      case 'salt':
      case 'location':
      case 'organisationId':
      case 'acceptedTCs':
      case 'userDBSync':
      case 'createdAt':
      case 'createdBy':
      case 'updatedAt':
      case 'updatedBy':
      case 'password':
      case 'signupUid':
        return Object.assign({}, accumulator, {
          [prop]: user[prop]
        })
      case 'name':
        return Object.assign({}, accumulator, {
          name: user.name
        })
      case 'programs':
        return Object.assign({}, accumulator, (
          user.programs && user.programs.length
            ? { programs: user.programs }
            : undefined
        ))
      case 'roles':
        return Object.assign({}, accumulator, {
          roles: denormaliseRoles(user.roles)
        })
      case 'funders':
        // SL and PSM uses funders, read this value from the config before submitting
        if (options.funders) {
          return Object.assign({}, accumulator, {
            funders: user.funders
          })
        }
        return Object.assign({}, accumulator)

      case 'routes':
        return Object.assign({}, accumulator, {
          routes: user.routes
        })
      default:
        return Object.assign({}, accumulator)
    }
  }, {})

  return decorateUser(deconstructProfile(denormalisedUser, user, options), options)
}
