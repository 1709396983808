import React from 'react'
import { Link } from 'react-router-dom'

import { DownloadButton, ImportButton, Page, Table, Text } from '@fielded/shared-ui'
import { arrayBufferFileReader } from '@fielded/shared-ui/src/components/ImportButton'

import shipmentsBreadcrumb from '../../../common/shipmentsBreadcrumb'
import { COLUMNS, ROWS } from './utils'
import ExportImportCard from '../../../../../van-shared/components/ExportImportCard/ExportImportCard'

const ACCEPTED_FILE_TYPES = [
  'text/csv'
]

const PSMShipmentExportImport = ({
  loading,
  onImport,
  onImported,
  onError
}) => {
  return (
    <Page>
      <Page.Header
        breadcrumbItems={[
          shipmentsBreadcrumb
        ]}
        title='Export & Import'
      />
      <Page.Panel>
        <ExportImportCard
          title='Create shipments'
          description='Upload a CSV file to create your shipments'
          button={
            <ImportButton
              buttonText='Upload shipments'
              accept={ACCEPTED_FILE_TYPES}
              loading={loading}
              onImport={onImport}
              onImported={data => onImported(data)}
              reader={arrayBufferFileReader}
              onError={onError}
            />
          }
          extraButton={
            <DownloadButton
              component={Link}
              target='_blank'
              download
              to='/psm/document-examples/shipment-template.csv'
              size='regular'
            >
              Download shipments template
            </DownloadButton>
          }
        >
          <Text>Here is an example how the excel file should look:</Text>
          <Table
            entries={ROWS}
            columns={COLUMNS}
            headerTextStyle='lowercase'
          />
          <Text><b>id</b> column contains the destination location id.</Text>
          <Text><b>product</b> column the product code</Text>
          <Text><b>quantity</b> column contains the quantity to be delivered</Text>
          <Text><b>origin</b> column contains the source location id</Text>
          <Text><b>external Id</b> column contains the external shipment id</Text>
        </ExportImportCard>
      </Page.Panel>
    </Page>
  )
}

export default PSMShipmentExportImport
