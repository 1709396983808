export default {
  admin: {
    role: 'feature:settings',
    displayName: 'Settings: Super Admin',
    description: 'Grants access to all Settings areas. If this is applied, other settings roles are not needed.'
  },
  facilities: {
    role: 'feature:settings:facilities',
    displayName: 'Settings: Facilities Admin',
    description: 'User can add and edit facilities.'
  },
  facilityApprovals: {
    role: 'feature:settings:facilities:approvals',
    displayName: 'Settings: Facilities Change Approvals',
    description: 'User can approve proposed Facilities master data changes'
  },
  facilityChanges: {
    role: 'feature:settings:facilities:changes',
    displayName: 'Settings: Facilities Change Requests',
    description: 'User can request proposed Facilities master data changes'
  },
  facilityReadonly: {
    role: 'feature:settings:facilities:readonly',
    displayName: 'Settings: Facilities (read only)',
    description: 'User can access and view Facilities master data but may not propose changes'
  },
  facilityProductAllocation: {
    role: 'feature:settings:facilities:products:allocation',
    displayName: 'Settings: Facilities: Product Allocations',
    description: 'User can view Facilities product allocation data'
  },
  manageFacilityProductSubscription: {
    role: 'feature:settings:facilities:products:subscription',
    displayName: 'Settings: Facilities: Manage Product Subscriptions',
    description: 'User can manage facilities product subscription list'
  },
  products: {
    role: 'feature:settings:products:edit',
    displayName: 'Settings: Products create and edit',
    description: 'User can view, create and edit the Products master data'
  },
  productsReadonly: {
    role: 'feature:settings:products:readonly',
    displayName: 'Settings: Products (read only)',
    description: 'User can access and view Products master data but may not propose changes'
  },
  users: {
    role: 'feature:settings:users',
    displayName: 'Settings: Users Admin',
    description: 'User can add and edit other users.'
  },
  routePlanning: {
    role: 'feature:settings:routePlanning',
    displayName: 'Route Planning',
    description: 'User can plan and modify routes. They then get forwarded for acceptance. Should be assigned to State LMCU or National Supply Chain Unit (NSCU) supervisor.'
  },
  routeReviewing: {
    role: 'feature:settings:routeReviewing',
    displayName: 'Route Reviewing',
    description: 'User can modify and review routes before forwarding them to Carrier. Should be assigned to Distribution Manager.'
  },
  carrierManagement: {
    role: 'feature:carrierManagement',
    displayName: 'Carrier Management',
    description: 'User can add and edit third party Carriers. Should be assigned to Distribution Manager.'
  },
  funders: {
    role: 'feature:funders',
    displayName: 'Funders',
    description: 'User can add and edit funders'
  },
  routes: {
    role: 'feature:routes',
    displayName: 'Routes',
    description: 'User can add and edit routes'
  },
  onlineOffline: {
    role: 'feature:online-offline',
    displayName: 'Sync: Online/Offline',
    description: 'User syncs less data for offline usage and access older data online-only'
  },
  ordersRequester: {
    role: 'feature:orders:requester',
    displayName: 'Orders: Request',
    description: 'User can create, edit, and submit orders for facilities'
  },
  ordersWarehouse: {
    role: 'feature:orders:warehouse',
    displayName: 'Orders: Warehouse',
    description: 'User can review, ration, and accept orders submitted by requesting users a behalf of a warehouse'
  },
  ordersBulk: {
    role: 'feature:orders:bulk',
    displayName: 'Orders: National Bulk Actions User',
    description: 'User can import rapid LMDs and Bulk Allocations (Reserved Stock)'
  },
  ordersReset: {
    role: 'feature:orders:reset',
    displayName: 'Orders: Reset',
    description: 'User can reset order groups to previous state or clear an order entirely'
  },
  historicDataEntry: {
    role: 'feature:edits-any-reporting-period',
    displayName: 'Historical Data Entry',
    description: 'User can edit stock reports in any previous period'
  },
  forecasting: {
    role: 'feature:forecasting',
    displayName: 'Forecasting Dashboard',
    description: 'User has access to the forecasting dashboard with a forecast of stock-outs and expiries'
  },
  planning: {
    role: 'feature:planning',
    displayName: 'Planning Dashboard',
    description: 'User has access to the planning dashboard with a forecast of stock-outs for rationing of provisional longhaul orders'
  },
  approveLonghaul: {
    role: 'feature:approve-longhaul',
    displayName: 'Approval of Long Haul orders',
    description: 'User with this role is able to approve the Long Haul order plans for review.'
  },
  createPrograms: {
    role: 'feature:create-programs',
    displayName: 'Programs',
    description: 'User has access to creating and editing Programs'
  },
  superProductAdmin: {
    role: 'feature:super-product-admin',
    displayName: 'Super Product Admin',
    description: 'This user has access to create and edit product bases and presentations in react admin',
    requiresRoleMatching: true
  }
}
