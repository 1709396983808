const { isWarehouseUser } = require('../../../order/tools/read/user')

const isSuperAdminUser = (user) => {
  return user.roles &&
    user.roles.includes('feature:settings')
}

const isDriver = (user) => {
  return user.roles &&
    user.roles.includes('userRole:psm-driver')
}

const isDistributionManager = (user) => {
  return user.roles &&
    user.roles.includes('userRole:psm-distribution-manager')
}

const isCarrier = (user) => {
  return user.roles &&
    user.roles.includes('userRole:psm-carrier')
}

// TODO: userBeforeUpdate can be null
const validateUser = (currentUser, userToSave, userBeforeUpdate = null) => {
  const {programs} = currentUser

  const isShelflife = programs.includes('program:shelflife')

  // Shelflife user featureset is much safer than PSM's, we have no validation rules here.
  if (isShelflife) return

  const warehouseUser = userBeforeUpdate && isWarehouseUser(userBeforeUpdate)

  const currentUserIsAdmin = isSuperAdminUser(currentUser)
  const userBeforeUpdateIsAdmin = userBeforeUpdate && isSuperAdminUser(userBeforeUpdate)
  const updatingCurrentUser = currentUser._id === userToSave._id

  const userToSaveHasRequesterRole = userToSave.roles.includes('orders:requester')
  const userToSaveHasWarehouseRole = userToSave.roles.includes('orders:warehouse')

  const userToSaveHasStockReportRole = userToSave.roles.includes('stock-reporting')
  const userToSaveHasShipmentRole = userToSave.roles.includes('shipments')

  if (userToSaveHasStockReportRole && !userToSaveHasShipmentRole) {
    return 'You need the shipment role to have the stock reporting entry role'
  }

  if ((currentUserIsAdmin && userBeforeUpdateIsAdmin && !userToSave.roles.includes('settings') && !updatingCurrentUser)) {
    return `You can't remove the super admin role for other super admins`
  }

  if (warehouseUser && userBeforeUpdate && userBeforeUpdate.location.id !== userToSave.location.id) {
    return 'Warehouse users cannot change location mapping from the UI'
  }

  if (userToSaveHasWarehouseRole && userToSaveHasRequesterRole) {
    return 'Warehouse users can have either the "Orders: Request" or "Orders: Warehouse" role and not both'
  }

  if (userToSaveHasWarehouseRole && userToSave.location.level === 'national' && userToSave.roles.includes('orders:bulk')) {
    return 'A national user can not have "Warehouse" role and "National Bulk Actions User" role'
  }

  if (userToSaveHasWarehouseRole && userToSave.roles.includes('online-offline')) {
    return 'Warehouse users cannot have the "online-offline" role'
  }

  if (!userToSaveHasWarehouseRole && !userToSave.roles.includes('online-offline')) {
    return 'You must have the "online-offline" role'
  }

  if (!isDriver(userToSave) && userToSave.location.level === 'national' &&
    userToSave.roles.includes('shipments') && !userToSave.roles.includes('global-shipments')) {
    return 'User should have the "Global shipments" role'
  }

  if (isDistributionManager(userToSave) && !userToSave.roles.includes('settings:routeReviewing')) {
    return 'Distribution managers must have the "Route Reviewing" role'
  }

  if (isDistributionManager(userToSave) && !userToSave.roles.includes('carrierManagement')) {
    return 'Distribution managers must have the "Carrier management" role'
  }

  if (isCarrier(userToSave) || isDriver(userToSave)) {
    const userType = isCarrier(userToSave) ? 'Carrier' : 'Driver'
    if (userToSave.location.level === 'national' && !currentUser.location.level === 'national') {
      return `${userType} at national level can only be created by national managers`
    }
  }
}

exports.validateUser = validateUser
