import React from 'react'
import PropTypes from 'prop-types'

import Text from '../../../Text'

// This character count was found together with the designer. If the title is longer
// than this it becomes too big, so we render the heading a bit smaller.
const LENGTH_THRESHOLD = 35

const PageHeaderTitle = ({
  title
}) => {
  const headingSize = title && title.length > LENGTH_THRESHOLD ? 'largish' : 'large'

  return title ? (
    <Text.Heading
      tag='h1'
      size={headingSize}
      tight
      className='vs-page-header__title'
    >
      {title}
    </Text.Heading>
  ) : null
}

PageHeaderTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
}

PageHeaderTitle.displayName = 'Page.ComposableHeader.Title'

export default PageHeaderTitle
