const {
  dateToWeeklyReportingPeriod,
  previousReportingPeriod,
  nextReportingPeriod,
  reportingPeriodToDate,
  endOfReportingPeriodToDate
} = require('./date-utils')
const reportProgress = require('./report-progress')
const smartId = require('./smart-id')
const docToStockCountRecord = require('./doc-to-stock-count-record')
const generateReportIds = require('./generate-report-ids')
const { locationIdToProperties, adminLocationPropsForCountry } = require('./location-id-to-properties')
const getSubmitProperties = require('./get-submit-properties')
const pickLastSubmittedReport = require('./pick-last-submitted-report')
const shouldTrackBatches = require('./should-track-batches')
const isShipmentRelevant = require('./is-shipment-relevant')
const stockCountIdToLocationProperties = require('./stock-count-id-to-location-properties')
const toStockCountId = require('./to-stock-count-id')
const translateReport = require('./translate-report')
const toDraftStockCountId = require('./to-draft-stock-count-id')
const draftLocationIdToProperties = require('./draft-location-id-to-properties')
const applyCalculatedField = require('./apply-calculated-field')
const { getLedgerBalance } = require('./get-ledger-balance')
const { ledgerBalanceToReport } = require('./ledger-balance-to-report')
const ledgerRowTools = require('./ledger-rows')
const order = require('../order/tools')
const {byId, byKey} = require('./by-id')
const {isShelflifeService, isBasicTierService} = require('./utils/program')
const { isDraft, fromDraft, toDraft } = require('./utils/draft')
const addStockCounts = require('./add-stock-counts')
const {createReportIdsOnProgram} = require('./create-report-ids-on-program')
const geo = require('./geo')
const { getLocation, periodIsActive } = require('./location-docs')
const { createLocationDoc } = require('./location-edits')
const filterInactiveLocations = require('./filter-inactive-locations')
const {
  validateLocationEdit,
  locationEditsToDoc,
  validateLocationCreate,
  deconstructNewLocation
} = require('./location-edits')
const serviceIdToProgramId = require('./service-id-to-program-id')
const {getPriceAt} = require('../stock/stock-situation/tools')
const proposal = require('../proposal/tools')
const product = require('../product/tools')
const {filterLocationsByProgram} = require('../location/tools/filters')

// not all functions are called through index.js in tests so istanbul complains
/* istanbul ignore next */
module.exports = {
  getPriceAt,
  dateToWeeklyReportingPeriod,
  previousReportingPeriod,
  nextReportingPeriod,
  reportProgress,
  docToStockCountRecord,
  generateReportIds,
  locationIdToProperties,
  adminLocationPropsForCountry,
  getSubmitProperties,
  pickLastSubmittedReport,
  shouldTrackBatches,
  stockCountIdToLocationProperties,
  toStockCountId,
  translateReport,
  reportingPeriodToDate,
  toDraftStockCountId,
  draftLocationIdToProperties,
  getLedgerBalance,
  ledgerBalanceToReport,
  endOfReportingPeriodToDate,
  applyCalculatedField,
  ledgerRowTools,
  isShipmentRelevant,
  order,
  byId,
  byKey,
  isShelflifeService,
  isBasicTierService,
  toDraft,
  isDraft,
  fromDraft,
  addStockCounts,
  createReportIdsOnProgram,
  getLocation,
  createLocationDoc,
  validateLocationEdit,
  locationEditsToDoc,
  validateLocationCreate,
  deconstructNewLocation,
  filterInactiveLocations,
  geo,
  periodIsActive,
  proposal,
  product,
  filterLocationsByProgram,
  serviceIdToProgramId,
  smartId
}
