const { validateOrganisationData, validatePriorities } = require('../../tools/validation-tools')

exports.create = async (adapter, data) => {
  validateOrganisationData(data)
  if (data.operationalStates) {
    validatePriorities(data.operationalStates)
  }

  const organisation = await adapter.create(data)
  return organisation
}
