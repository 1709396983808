import get from 'lodash/get'

import { userIsAuthorised } from '../../van-shared/utils/auth'
import { hasFeature } from '../../van-shared/utils/features'
import { hasUserContract } from '../../subapps/settings/common/utils'
import capitalize from '../../common/utils/capitalize'
import { isLastMileDeliveryUser } from '../../subapps/shipments/common/utils'

/**
 * FIXME: This is an interim hack around the shortcomings of the current
 * authentication framework.
 *
 * See: https://github.com/fielded/docs/pull/63
 */
const userHasSubRole = (user, role) => user.roles.find(r => r.startsWith(role))

export const getAdministrationSubitems = (features, user) => {
  const isVendor = user.roles.includes('feature:userRole:psm-vendor')
  const administrationItems = []

  const isShelflife = hasFeature(features, 'settings:shelflife')
  const isRetailer = userIsAuthorised(user, 'feature:userRole:pharmacyUser')
  const isCarrier = userIsAuthorised(user, 'feature:userRole:psm-carrier')
  const hasUserAdmin = hasFeature(features, 'settings:users') &&
    userIsAuthorised(user, 'feature:settings:users')
  const hasRouteAdminSL = isShelflife && hasFeature(features, 'settings:routes:edit') &&
    userIsAuthorised(user, 'feature:settings:funders')
  const hasFunderAdminPSM = hasFeature(features, 'settings:funders:edit') &&
    userIsAuthorised(user, 'feature:funders')
  const hasRouteAdminPSM = hasFeature(features, 'routesAdmin') && hasFeature(features, 'settings:routes:edit') &&
    userIsAuthorised(user, 'feature:routes')

  // Shelf Life pharmacy users have access to some parts of location admin
  // but not to others:
  // - they can see contracts and subscriptions but not location details
  // - they should see a different item in the main menu
  // (instead of Administration > Locations)
  const hasLocationAdmin = hasFeature(features, 'settings:facilities') &&
    (userHasSubRole(user, 'feature:settings:facilities') || userIsAuthorised(user, 'feature:settings:facilities')) && !isRetailer

  const hasProductsAdmin = hasFeature(features, 'settings:products') &&
    (userHasSubRole(user, 'feature:settings:products') || userIsAuthorised(user, 'feature:settings:products'))
  const hasPlanningCalculator = hasFeature(features, 'settings:planningCalculator') &&
    (userHasSubRole(user, 'feature:settings:planning-calculator') || userIsAuthorised(user, 'feature:settings:planning-calculator'))
  const hasPrograms = hasFeature(features, 'settings:programs') && userIsAuthorised(user, 'feature:create-programs') && userIsAuthorised(user, 'feature:userRole:psm-national')
  const hasRoutePlanning = (
    hasFeature(features, 'routePlanning') &&
    (userIsAuthorised(user, 'feature:settings:routePlanning') || userIsAuthorised(user, 'feature:settings:routeReviewing') || isCarrier)
  )
  const hasWareHouseStockUpdate = hasFeature(features, 'settings:stockUpdate') && userIsAuthorised(user, 'feature:orders:warehouse')

  const hasCarrierManagement = hasFeature(features, 'routePlanning') && userIsAuthorised(user, 'feature:carrierManagement')

  if (hasRoutePlanning) {
    administrationItems.push({ path: '/settings/routePlanning', label: 'Route plan' })
  }

  if (hasLocationAdmin) {
    administrationItems.push({ path: '/settings/facilities', label: 'Locations' })
  }

  if (hasCarrierManagement) {
    administrationItems.push({ path: '/settings/carriers', label: 'Carrier' })
  }

  if (hasUserAdmin) {
    administrationItems.push({ path: '/settings/users', label: isVendor || isCarrier ? 'Drivers' : 'Users' })
  }

  if (hasProductsAdmin) {
    administrationItems.push({ path: '/settings/products', label: `${isShelflife ? 'Product price lists' : 'Products'}` })
  }

  if (hasWareHouseStockUpdate) {
    administrationItems.push({ path: '/settings/stock-update', label: 'Stock update' })
  }

  if (hasPlanningCalculator) {
    administrationItems.push({ path: '/settings/planning-calculator', label: 'Planning calculator' })
  }

  if (hasPrograms) {
    administrationItems.push({ path: '/settings/programs', label: 'Programs' })
  }

  if (hasRouteAdminSL || hasRouteAdminPSM) {
    const routeName = get(features, 'settings.routes.noun')
    administrationItems.push({ path: `/settings/${routeName}s`, label: `${capitalize(routeName)}s` })
  }

  if (hasFunderAdminPSM) {
    const funderName = get(features, 'settings.funders.noun')
    administrationItems.push({ path: `/settings/${funderName}s`, label: `${capitalize(funderName)}s` })
  }

  return administrationItems
}

export const getMyAccountSubitems = (user, isBasicUser, isLeadUser, isSLReseller, pendingContracts, outdatedContracts) => {
  const myAccountItems = []

  let contractsToSignAmount = pendingContracts
  if (outdatedContracts) {
    contractsToSignAmount = pendingContracts + outdatedContracts.length
  }

  const pendingContractsBadge = contractsToSignAmount > 0 ? contractsToSignAmount.toString() : undefined

  const isRetailer = userIsAuthorised(user, 'feature:userRole:pharmacyUser')
  const driverWithContracts = hasUserContract(user)
  const canViewSourceLedger = userIsAuthorised(user, 'feature:can-view-source-ledger')

  myAccountItems.push(
    { path: '/profile', label: 'My profile' }
  )

  if (isRetailer && !canViewSourceLedger) {
    myAccountItems.push(
      { path: '/retailer/profile/plan', label: 'My plan' }
    )
    if (!isBasicUser && !isLeadUser && !isSLReseller) {
      myAccountItems.push(
        { path: '/retailer/profile/contracts', label: 'Contracts', badgeLabel: pendingContractsBadge }
      )
    }
  }

  if (driverWithContracts) {
    myAccountItems.push(
      { path: '/profile/fp-agreement', label: 'Contracts', badgeLabel: pendingContractsBadge }
    )
  }

  return myAccountItems
}

export const getAnalyticsSubitems = (features, user) => {
  let analyticsItems

  const hasForecasting = hasFeature(features, 'forecasting') &&
  userIsAuthorised(user, 'feature:forecasting')

  if (hasForecasting) {
    analyticsItems = [
      { path: '/analytics', label: 'Analytics' },
      { path: '/analytics/forecast', label: 'Forecasting' }
    ]
  }

  return analyticsItems
}

export const getShipmentsSubitems = (features, user) => {
  const hasShipments = hasFeature(features, 'shipments') && userIsAuthorised(user, 'feature:shipments')
  const hasLastMileDeliveries = hasFeature(features, 'lastMileDeliveries') && isLastMileDeliveryUser(user)
  const hasShipmentUploadAccess = hasFeature(features, 'shipments:uploadShipments') && userIsAuthorised(user, 'feature:uploadShipments')

  let shipmentsItems = []

  if ((hasShipments && hasLastMileDeliveries) || hasShipmentUploadAccess) {
    shipmentsItems = [{ path: '/shipments/last-mile-deliveries', label: 'Last mile deliveries' }]

    if (hasShipmentUploadAccess) {
      shipmentsItems.unshift({ path: '/shipments/export-import', label: 'Export & Import' })
    } else {
      shipmentsItems.unshift({ path: '/shipments/list', label: 'National shipments' })
    }
  }

  return shipmentsItems
}

export const getOrdersSubitems = (config, user) => {
  const orderEntityName = get(config, 'features.order.appEntityName', 'Order')
  const isWarehouseUser = userIsAuthorised(user, 'feature:orders:warehouse')

  const ordersItems = [{
    path: '/orders/',
    label: `${orderEntityName} summaries`,
    isActiveSlug: '/program'
  }]

  // TODO: remove top up import code or fix it.
  // Temporarily disable topup import for orders user
  // and instead use create for location in order/create
  const disableOrderBulk = get(config, 'features.order.disableBulk', false)
  if (user.roles.some(role => role.includes('orders:bulk')) && !disableOrderBulk) {
    ordersItems.push({
      path: '/orders/bulk',
      label: 'Export & Import',
      isActiveSlug: '/orders/bulk'
    })

    if (isWarehouseUser) {
      ordersItems.push({
        path: '/orders/edit-allocation',
        label: 'Edit Allocation',
        isActiveSlug: '/orders/edit-allocation'
      })
    }
  }

  return ordersItems
}
