const { EntityApi } = require('../common')
const OrganisationPGAdapter = require('./data-access/organisation-pg-adapter')
const api = require('./api')

class OrganisationRestAdapter {
  constructor (restAdapter) {
    this.path = 'organisation'
    if (restAdapter && restAdapter.extend) {
      restAdapter.extend(this, this.path)
      this.restAdapter = restAdapter
    }
  }

  async create (data) {
    return this.restAdapter.post(`${this.path}/create`, data)
  }

  async list () {
    return this.restAdapter.get(`${this.path}/list`)
  }

  async getById (id) {
    return this.restAdapter.get(`${this.path}/getById/${id}`)
  }

  async update (id, data) {
    return this.restAdapter.update(`${this.path}/update/${id}`, data)
  }
}

class OrganisationApi extends EntityApi {
  constructor (state, pgConnection, agaveAdapter, logger) {
    const { user } = state
    const adapter = !pgConnection
      ? new OrganisationRestAdapter(agaveAdapter)
      : new OrganisationPGAdapter(logger, pgConnection, user.name)

    super(adapter)
    this.adapter = adapter
  }

  async update (id, data) {
    return api.update(this.adapter, id, data)
  }

  async getById (id) {
    return api.getById(this.adapter, id)
  }
}

module.exports = OrganisationApi
