const PGAdapter = require('../../common/pg-adapter')
const { DATABASE, COLUMNS, SQL } = require('./constants')
const { v4: uuidv4 } = require('uuid')

class FleetCategoryPGAdapter extends PGAdapter {
  constructor (logger, pgConnection, username) {
    super(
      pgConnection,
      `${DATABASE.SCHEMA}.${DATABASE.TABLES.FLEET_CATEGORY}`,
      username,
      COLUMNS.fleetCategory,
      'id',
      {},
      logger
    )
  }

  async create (id, data, dates, client = this.pgConnection) {
    const { rows: [fleet] } = await client.query(SQL.CREATE_FLEET_CATEGORY, [
      uuidv4(),
      id,
      data.category,
      data.comment,
      dates.created_at,
      dates.updated_at
    ])
    return fleet
  }

  async update (carrierId, fleets, dates, client = this.pgConnection) {
    const { rows } = await client.query(SQL.UPDATE_FLEET_CATEGORIES, [
      Array(fleets.length).fill().map(() => uuidv4()),
      carrierId,
      fleets.map(f => f.category),
      fleets.map(f => f.comment),
      dates.created_at,
      dates.updated_at
    ])
    return rows
  }

  async delete (id, client = this.pgConnection) {
    await client.query(SQL.DELETE_FLEET_CATEGORY, [id])
  }
}

module.exports = FleetCategoryPGAdapter
