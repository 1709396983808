import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'

import { Checkbox } from '@fielded/shared-ui'

import { selectUsingFlags, flagUsageUpdate } from '../reducers/common/userSession'

const CurrentEnvironmentBanner = ({environment}) => {
  const dispatch = useDispatch()
  // we want to show PR preview number for environment
  // that matches this pattern
  const regexPreview = /^pr-\d+/
  const regexPSM = /^staging\.(psm|nscip|epn|meds)/
  const host = window.location.host
  if (regexPreview.test(host)) {
    const previewNumber = host.split('.')[0].replace('pr-', '')
    environment = `preview-${previewNumber}`
  }

  const isFlagUsageOn = useSelector(selectUsingFlags)

  // This is temporary feature enabling users to test on staging with some of the features on
  const showSwitcher = regexPSM.test(host)
  const onChangeFlagSwitch = () => {
    const action = isFlagUsageOn ? 'Disabling' : 'Enabling'
    const response = window.confirm(`${action} these features will refresh your browser. Do you want to proceed?`)

    if (response) {
      dispatch(flagUsageUpdate(!isFlagUsageOn))
      window.location.reload()
    }
  }

  return (
    <div className={classnames(
      'current-env-banner',
      { 'current-env-banner--funky-mode': isFlagUsageOn }
    )}>
      <p className='current-env-banner__text'>
        {environment} environment
      </p>
      {showSwitcher && (
        <Checkbox.Stateless
          className='current-env-banner__switcher'
          label='Long Haul, TMS, ePOD features on'
          onChange={() => onChangeFlagSwitch()}
          checked={isFlagUsageOn}
        />
      )}
    </div>
  )
}

export default CurrentEnvironmentBanner
