module.exports = {
  locationIdToProperties,
  adminLocationPropsForCountry
}

const PSM_NATIONAL_USER_LOCATION_ID = 'national'

// This is for getting the location object for PSM national level users.
// National Kenyan users use the expected location.id containing the country code.
// National Nigerian users use the location.id 'national', for compatibility with
// the existing data.
function adminLocationPropsForCountry (countryId) {
  return countryId === 'country:ng' ? locationIdToProperties(PSM_NATIONAL_USER_LOCATION_ID)
    : locationIdToProperties(countryId)
}

function locationIdToProperties (id) {
  // This is for PSM NG only, PSM KE will use `country:ke` as id
  if (id === PSM_NATIONAL_USER_LOCATION_ID) {
    return {
      country: 'ng', // default to NG if no country provided
      level: 'national',
      id
    }
  }

  const [, country] = id.match(/country:([^:]+)/) || []
  const [, zone] = id.match(/zone:([^:]+)/) || []
  const [, state] = id.match(/state:([^:]+)/) || []
  const [, lga] = id.match(/lga:([^:]+)/) || []
  const [, sdp] = id.match(/sdp:([^:]+)/) || []
  const [, name] = id.match(/name:([^:]+)/) || []

  const locationId = []
  const properties = {
    country: 'ng'
  }

  if (country) {
    properties.level = 'national'
    properties.country = country
    locationId.push('country', country)
  }
  if (zone) {
    properties.level = 'zone'
    properties.zone = zone
    locationId.push('zone', zone)
  }
  if (state) {
    properties.state = state
    properties.level = 'state'
    locationId.push('state', state)
  }
  if (lga) {
    properties.lga = lga
    properties.level = 'lga'
    locationId.push('lga', lga)
  }
  if (sdp) {
    properties.sdp = sdp
    properties.level = 'sdp'
    locationId.push('sdp', sdp)
  }
  if (name) {
    properties.name = name
    locationId.push('name', name)
  }
  properties.id = locationId.join(':')
  return properties
}
